import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"

const AdminAllItems = ({ data }) => {
  const [tags, setTags] = useState("")

  function convertTags(tags) {
    const arr = tags.split(" ")
    const tmp = '["' + arr.join('", "')
    const size = tmp.length
    const result = tmp.substring(0, size - 3) + "],"
    setTags(result)
  }

  function setClip() {
    navigator.clipboard.writeText(tags)
  }

  return (
    <Layout>
      <section className="w-full md:w-3/4 flex flex-col px-3">
        <div className="fixed h-24 w-3/5 bg-blue-200 p-2 border border-gray-400 rounded">
          <input
            onChange={e => convertTags(e.target.value)}
            type="search"
            placeholder="半角スペース区切りでtagを入力"
            className="w-full bg-gray-200 text-sm text-gray-700 transition border border-gray-400 focus:outline-none focus:border-gray-500 rounded py-1 px-2 appearance-none leading-normal"
          ></input>
          <div className="flex items-center py-2">
            <div className="w-8 h-8 bg-white mr-3" onClick={() => setClip()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="fill-current w-8 h-8 mr-2"
              >
                <path d="M20.12 11.95l-6.58 6.59a5 5 0 1 1-7.08-7.07l6.59-6.6a3 3 0 0 1 4.24 4.25l-6.58 6.59a1 1 0 1 1-1.42-1.42l6.59-6.58a1 1 0 0 0-1.42-1.42l-6.58 6.59a3 3 0 0 0 4.24 4.24l6.59-6.58a5 5 0 0 0-7.08-7.08l-6.58 6.6a7 7 0 0 0 9.9 9.9l6.59-6.6a1 1 0 0 0-1.42-1.4z" />
              </svg>
            </div>
            <span>tag:{tags}</span>
          </div>
        </div>
        <div className="h-24 w-full"></div>
        <h3 className="font-semibold mb-3 text-xl leading-tight sm:leading-normal">
          All Items
        </h3>
        <div className="images grid grid-cols-1 gap-3">
          {data.allSvgItemsJson.edges.map(({ node }, index) => (
            <div
              key={index}
              className="image h-20 bg-white rounded border border-gray-400 flex "
            >
              <div className="h-full text-lg font-bold py-4 mx-2">
                {node.id}
              </div>
              <img
                src={"/images/" + node.cat + "/" + node.name + ".svg"}
                className="max-h-full mx-6 py-3"
                loading="lazy"
              ></img>
              <div>
                <p>カテゴリ：{node.cat}</p>
                <p>名前：{node.name}</p>
                <p>タグ：{node.tag.join(" ")}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  )
}
export default AdminAllItems

export const query = graphql`
  query {
    allSvgItemsJson(sort: { fields: id, order: DESC }) {
      edges {
        node {
          id
          cat
          name
          tag
        }
      }
      totalCount
    }
  }
`
